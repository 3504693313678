// store.js
import { configureStore } from '@reduxjs/toolkit'; 
import authSlice from './pages/auth/redux/authSlice';
import customAttributeSlice from './pages/contacts/customattr/redux/customAttributeSlice';
import subscriberSlice from './pages/contacts/subscribers/redux/subscriberSlice';
import listSlice from './pages/contacts/lists/redux/listSlice';
import segmentSlice from './pages/contacts/segments/redux/segmentSlice';
import campaignSlice from './pages/campaign/redux/campaignSlice';

const store = configureStore({
  reducer: {
    auth:authSlice,
    customAttribute: customAttributeSlice,
    subscriber: subscriberSlice,
    list: listSlice,
    segment: segmentSlice,
    campaign: campaignSlice
  },
  devTools: true,
});

export default store;