import React from 'react'

const CampaignSchedule = ({ campaign }) => {
    return (
        <>
            <div className='campaign-schedule'>
                <div className='my-3'>
                    <button className='btn btn-dark'>Send Now</button>
                </div>
                <div class="line-or">OR</div>
                <div className='my-3 '>
                    <input type="date" className='form-control mb-3 campaign-schedule-input' />
                    <input type="time" className='form-control mb-3 campaign-schedule-input' />
                    <button className='btn btn-dark mb-3'>Schedule</button>
                </div>
            </div>

        </>
    )
}

export default CampaignSchedule