import api from '../../../config';
import { deleteSegmentSuccess } from '../../contacts/segments/redux/segmentSlice';
import { campaignFailure, campaignStart, createCampaignSuccess, getCampaignsSuccess } from './campaignSlice';

export const createCampaign = (values) => async (dispatch) => {
    try {
        dispatch(campaignStart());
        const response = await api.post('/campaigns/store', values);
        dispatch(createCampaignSuccess(response.data.campaign));
    } catch (error) { 
        dispatch(campaignFailure(error.response.data.message));
    }
};

export const getAllCampaigns = (values) => async (dispatch) => {
    try {
        dispatch(campaignStart());
        const response = await api.get('/campaigns/get', values);
        dispatch(getCampaignsSuccess(response.data.campaigns));
    } catch (error) {
        dispatch(campaignFailure(error.response.data.error));
    }
};

export const deleteCampaign = (id) => async (dispatch) => {
    try {
        dispatch(campaignStart());
        const response = await api.get('/campaigns/delete/'+id);
        dispatch(deleteSegmentSuccess(response.data.id));
    } catch (error) {
        dispatch(campaignFailure(error.response.data.error));
    }
};

