import React, { useEffect, useState } from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getLists } from '../contacts/lists/redux/listActions';
import { getSegments } from '../contacts/segments/redux/segmentActions';
import CampaignContent from './CampaignContent';

const CampaignAdd = ({ handleAddCampaign }) => {

    const options = [{ value: '0', label: 'All subscribers', key: '1' }];
    const lists = useSelector(state => state.list.lists);
    const segments = useSelector(state => state.segment.segments);
    const [errors, setErrors] = useState([]);
    const [contentPage, setContentPage] = useState(false);



    const dispatch = useDispatch();

    useEffect(() => {
        if (!lists || lists.length === 0) {
            dispatch(getLists());
        }

        if (!segments || segments.length === 0) {
            dispatch(getSegments());
        }
    }, [lists, segments]);

    const listOptions = lists.map((attribute, index) => {
        return {
            value: attribute.id,
            label: attribute.name,
            key: '2'
        };
    });

    const segmentOptions = segments.map((attribute, index) => {
        return {
            value: attribute.id,
            label: attribute.name,
            key: '3'
        };
    });

    const allOptions = [
        {
            label: 'All Subscribers',
            options: options,
        },
        {
            label: 'Segments',
            options: segmentOptions,
        },
        {
            label: 'Groups',
            options: listOptions,
        }
    ]



    const [data, setData] = useState({
        subject: '',
        senderName: '',
        senderEmail: '',
        preheader: '',
        recipients: '',
    });

    const handleForm = () => {
        let temp = [];
        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] == '') {
                temp.push(key + ' field is required');
            }
        }
        setErrors(temp);
        // 
        if (temp.length == 0) {
            setContentPage(true);
        }

    }

    const handleRecipients = (opt) => {
        const items = opt;

        const allsub = items.filter((item) => item.value === '0');

        if (allsub.length > 0) {
            setData({ ...data, recipients: allsub });
        } else {
            setData({ ...data, recipients: opt });
        }
    }


    return (
        <>
            <div className='campaign-add'>
                <div className="campaign-header">
                    <div className='breadcrumb'>Add Campaign {`>`} Review and Schedule</div>
                    <div className='slideClose' onClick={handleAddCampaign}>
                        <IoClose size={30} />
                    </div>
                </div>
                {
                    !contentPage ?
                        <div className="campaign-content-container">
                            <h2>Campaign details</h2>
                            <p>Enter 'Subject' and other sending details.
                                Check preview on the side to see how your campaign will appear in the inbox.</p>
                            <div className="campaign-content">
                                <div className='row'>
                                    {
                                        errors.length > 0 &&
                                        <div className="fv-row mt-8">
                                            <div className="alert alert-danger">
                                                All fields are required
                                            </div>
                                        </div>
                                    }
                                    <div className="fv-row mb-4">
                                        <label>Recipients</label>
                                        <Select
                                            isMulti
                                            onChange={handleRecipients}
                                            options={allOptions}
                                            value={data.recipeients}
                                        />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <label>Campaign name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Campaign Name"
                                            className={`form-control bg-transparent`}
                                            autoFocus
                                            value={data.name}
                                            onChange={(e) => setData({ ...data, name: e.target.value })}
                                        />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <label>Subject</label>
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            className={`form-control bg-transparent`}
                                            value={data.subject}
                                            onChange={(e) => setData({ ...data, subject: e.target.value })}
                                        />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <label>Sender name</label>
                                        <input
                                            type="text"
                                            name="senderName"
                                            placeholder="Sender name"
                                            className={`form-control bg-transparent`}
                                            value={data.senderName}
                                            onChange={(e) => setData({ ...data, senderName: e.target.value })}
                                        />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <label>Sender email</label>
                                        <input
                                            type="text"
                                            name="senderEmail"
                                            placeholder="Sender email"
                                            className={`form-control bg-transparent`}
                                            value={data.senderEmail}
                                            onChange={(e) => setData({ ...data, senderEmail: e.target.value })}
                                        />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <label>Preheader</label>
                                        <input
                                            type="text"
                                            name="preheader"
                                            placeholder="Preheader"
                                            className={`form-control bg-transparent`}
                                            value={data.preheader}
                                            onChange={(e) => setData({ ...data, preheader: e.target.value })}
                                        />
                                    </div>
                                    <div className="fv-row mb-4">
                                        <button onClick={handleForm} className='btn btn-dark'>Next</button>
                                    </div>
                                </div>
                                <div className='campaign-preview'>
                                    <div className="preview-container">
                                        <div className="preview-header">
                                            <FaEnvelope /> Inbox preview
                                        </div>
                                        <div className="preview-content">
                                            <span className='senderName'>{data.senderName || 'Sender name'}</span><br />
                                            <span className='pc-text'>{data.subject || 'Your email subject will appear here.'} </span><br />
                                            <span className='pc-text'>{data.preheader || 'Your email preheader will appear here.'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <CampaignContent emailData={data} handleAddCampaign={handleAddCampaign} />
                }

            </div>
        </>
    )
}

export default CampaignAdd